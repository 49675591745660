const getEstadoString = (id) =>
  id == 1
    ? "Aguascalientes"
    : id == 2
    ? "Baja California"
    : id == 3
    ? "Baja California Sur"
    : id == 4
    ? "Campeche"
    : id == 5
    ? "Coahuila de Zaragoza"
    : id == 6
    ? "Colima"
    : id == 7
    ? "Chiapas"
    : id == 8
    ? "Chihuahua"
    : id == 9
    ? "Ciudad de México"
    : id == 10
    ? "Durango"
    : id == 11
    ? "Guanajuato"
    : id == 12
    ? "Guerrero"
    : id == 13
    ? "Hidalgo"
    : id == 14
    ? "Jalisco"
    : id == 15
    ? "Estado de México"
    : id == 16
    ? "Michoacán de Ocampo"
    : id == 17
    ? "Morelos"
    : id == 18
    ? "Nayarit"
    : id == 19
    ? "Nuevo León"
    : id == 20
    ? "Oaxaca"
    : id == 21
    ? "Puebla"
    : id == 22
    ? "Querétaro"
    : id == 23
    ? "Quintana Roo"
    : id == 24
    ? "San Luis Potosí"
    : id == 25
    ? "Sinaloa"
    : id == 26
    ? "Sonora"
    : id == 27
    ? "Tabasco"
    : id == 28
    ? "Tamaulipas"
    : id == 29
    ? "Tlaxcala"
    : id == 30
    ? "Veracruz"
    : id == 31
    ? "Yucatán"
    : id == 32
    ? "Zacatecas"
    : id == 33
    ? "Desconocio"
    : "";

export default getEstadoString;
