export const diccionario = {
  "-1": "Baja",
  0: "Nuevo",
  1: "Activo",
  2: "Inactivo",
};

export const getString = (id) => diccionario[id] || "No disponible";

export default getString;
