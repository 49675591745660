export const diccionarioStatus = {
  0: "Nueva",
  1: "Emitida Pendiente de Pago",
  2: "Pagada Parcial",
  3: "Pagada Total",
  4: "Renovada",
  5: "Finalizada",
  8: "Duplicada",
};

export const getStatusString = (id) => diccionarioStatus[id] || "Cancelada";

export default getStatusString;
