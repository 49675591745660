export const diccionario = {
  1: "Tarjeta de Crédito",
  2: "Tarjeta de Débito",
  3: "Depósito Bancario",
  4: "Transferencia",
  5: "Cheque",
  6: "Efectivo",
  7: "Domiciliación",
};

export const getString = (id) => diccionario[id] || "No disponible";

export default getString;
