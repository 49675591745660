export const diccionarioPlan = {
  1: "Amplia",
  2: "Amplia-Plus",
  3: "Limitada",
  4: "RC",
};

export const getPlanString = (id) => diccionarioPlan[id] || "No disponible";

export default getPlanString;
